import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MarkdownWithHtml from 'react-markdown/with-html'
import BackgroundImage from 'gatsby-background-image'
import Fade from 'react-reveal/Fade'

import styles from './winePalette.module.css'

const WinePalette = ({ palette }) => {
  if (!palette.description) {
    return null
  }

  return (
    <BackgroundImage
      Tag="section"
      id="palette"
      className={styles.root}
      fluid={palette.image.childImageSharp.fluid}
      aria-labelledby="section-palette-heading"
    >
      <div className={styles.wrapper}>
        <Fade delay={250}>
          <div className={styles.content} tabIndex={0}>
            <h2 className="title" id="section-palette-heading">
              The Palette
            </h2>
            <h3 className={classNames('subtitle', styles.subtitle)}>
              Wine Characteristics
            </h3>
            <MarkdownWithHtml
              source={palette.description}
              escapeHtml={false}
              className={styles.description}
            />
          </div>
        </Fade>
      </div>
    </BackgroundImage>
  )
}

WinePalette.propTypes = {
  palette: PropTypes.object.isRequired,
}

export default WinePalette
