import React from 'react'
import PropTypes from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import classNames from 'classnames'

import styles from './relatedWine.module.css'
import { link as linkStyle } from './wineList.module.css'

import Image from './image'
import BuyButton from './buyButton'

const RelatedWine = ({ wine }) => {
  const { fields, frontmatter } = wine

  return (
    <section aria-labelledby="section-related-wine" className={styles.root}>
      <div className={styles.wrapper}>
        {frontmatter.featured_image && (
          <AniLink to={fields.slug} paintDrip hex={frontmatter.featured_color}>
            <Image
              node={frontmatter.featured_image.src}
              alt={frontmatter.featured_image.alt}
            />
          </AniLink>
        )}
        <div className={styles.content} tabIndex={0}>
          <h2 id="section-related-wine" className="title title--small">
            {frontmatter.title}
          </h2>
          <h3
            className={classNames('subtitle subtitle--small', styles.subtitle)}
          >
            {frontmatter.origin}
          </h3>
          <BuyButton node={wine} />
          <br />
          <AniLink
            to={fields.slug}
            paintDrip
            hex={frontmatter.featured_color}
            className={linkStyle}
          >
            More Information
            <br /> about {frontmatter.title}
          </AniLink>
        </div>
      </div>
    </section>
  )
}

RelatedWine.propTypes = {
  wine: PropTypes.object.isRequired,
}

export default RelatedWine
