import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import classNames from 'classnames'
import Link from 'gatsby-plugin-transition-link'
import Fade from 'react-reveal/Fade'

import styles from './society.module.css'

import Image from './image'
import IconPromotions from '../images/icon-promotions.inline.svg'
import IconEvents from '../images/icon-events.inline.svg'
import IconSweepstakes from '../images/icon-sweepstakes.inline.svg'
import IconPairings from '../images/icon-pairings.inline.svg'

export default () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          filter: { frontmatter: { template: { eq: "join" } } }
          sort: { fields: [frontmatter___sort], order: ASC }
          limit: 1
        ) {
          totalCount
          edges {
            node {
              id
              fields {
                slug
              }
              html
              frontmatter {
                title
                featured_image {
                  src {
                    publicURL
                    childImageSharp {
                      fixed(width: 560) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                  alt
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      if (!data.allMarkdownRemark.totalCount) {
        return null
      }

      const { fields, html, frontmatter } = data.allMarkdownRemark.edges[0].node

      return (
        <section
          id="kris-society"
          className={styles.root}
          aria-labelledby="section-kris-society-heading"
        >
          <div className={styles.wrapper}>
            <Fade delay={250}>
              <div className={styles.main}>
                <h2
                  id="section-kris-society-heading"
                  className={classNames('title title--bar-after', styles.title)}
                >
                  {frontmatter.title}
                </h2>
                <div
                  tabIndex={0}
                  className={styles.content}
                  dangerouslySetInnerHTML={{ __html: html }}
                />
                <Link
                  to={fields.slug}
                  className="button button--green button--wide"
                >
                  Join Today!
                </Link>
              </div>
            </Fade>
            {frontmatter.featured_image && (
              <Fade>
                <aside className={styles.image}>
                  <Image
                    node={frontmatter.featured_image.src}
                    alt={frontmatter.featured_image.alt}
                    className="framed-image"
                  />
                  <ul className={styles.highlights} tabIndex={0}>
                    <li>
                      <IconPromotions />
                      Exclusive
                      <br /> Promotions
                    </li>
                    <li>
                      <IconEvents />
                      VIP
                      <br /> Events
                    </li>
                    <li>
                      <IconSweepstakes />
                      Special
                      <br /> Sweepstakes
                    </li>
                    <li>
                      <IconPairings />
                      Wine Pairings
                      <br /> and much more!
                    </li>
                  </ul>
                </aside>
              </Fade>
            )}
          </div>
        </section>
      )
    }}
  />
)
