import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import WineDetails from '../components/wineDetails'
import WinePalette from '../components/winePalette'
import WinePairings from '../components/winePairings'
import Society from '../components/society'
import RelatedWine from '../components/relatedWine'

export default ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark

  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        seoTitle={frontmatter.seo_title}
        description={frontmatter.seo_description}
      />
      <WineDetails wine={markdownRemark} />
      <WinePalette palette={frontmatter.palette} />
      <WinePairings wine={markdownRemark} />
      <Society />
      <RelatedWine wine={frontmatter.related_wine.childMarkdownRemark} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        name
        slug
      }
      frontmatter {
        title
        seo_title
        seo_description
        origin
        featured_image {
          src {
            publicURL
            childImageSharp {
              fixed(width: 450) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          alt
        }
        featured_color
        appellation
        varietals
        palette {
          description
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1680) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        pairings {
          description
          images {
            primary {
              src {
                publicURL
                childImageSharp {
                  fixed(width: 460) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              alt
            }
            secondary {
              src {
                publicURL
                childImageSharp {
                  fixed(width: 360) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              alt
            }
          }
        }
        purchase_links {
          label
          url
        }
        related_wine {
          childMarkdownRemark {
            id
            fields {
              slug
            }
            frontmatter {
              title
              origin
              featured_image {
                src {
                  publicURL
                  childImageSharp {
                    fixed(width: 280) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                alt
              }
              purchase_links {
                label
                url
              }
              featured_color
            }
          }
        }
      }
    }
  }
`
