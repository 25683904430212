import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MarkdownWithHtml from 'react-markdown/with-html'
import Fade from 'react-reveal/Fade'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import styles from './winePairings.module.css'

import Image from './image'

const WinePairings = ({ wine }) => {
  const { fields, frontmatter } = wine
  const { pairings } = frontmatter

  if (!pairings.description) {
    return null
  }

  return (
    <section
      className={classNames(`section-pairings-${fields.name}`, styles.root)}
      aria-labelledby="section-pairings-heading"
    >
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <Fade delay={500}>
            <div className={styles.content} tabIndex={0}>
              <h2
                className={classNames(
                  'title title--small title--white',
                  styles.title
                )}
                id="section-pairings-heading"
              >
                <em>Recommended</em> Food Pairings
              </h2>
              <MarkdownWithHtml
                source={pairings.description}
                escapeHtml={false}
                className={styles.description}
              />
              <AniLink
                to="/recipes"
                paintDrip
                hex={frontmatter.featured_color}
                className="button button--white-outline"
              >
                View Recipes
              </AniLink>
            </div>
          </Fade>
          {pairings.images && (
            <figure className={styles.images}>
              {pairings.images.primary && (
                <Fade>
                  <Image
                    node={pairings.images.primary.src}
                    alt={pairings.images.primary.alt}
                    className={classNames('framed-image', styles.primaryImage)}
                  />
                </Fade>
              )}
              {pairings.images.secondary && (
                <Fade delay={250}>
                  <div className={styles.secondaryImage}>
                    <Image
                      node={pairings.images.secondary.src}
                      alt={pairings.images.secondary.alt}
                      className="framed-image"
                    />
                  </div>
                </Fade>
              )}
            </figure>
          )}
        </div>
      </div>
    </section>
  )
}

WinePairings.propTypes = {
  wine: PropTypes.object.isRequired,
}

export default WinePairings
