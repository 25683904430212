import { Component } from 'react'
import ReactDOM from 'react-dom'

// Use a ternary operator to make sure that the document object is defined
const portalRoot =
  typeof document !== 'undefined' ? document.getElementById('___portal') : null

export default class Portal extends Component {
  constructor() {
    super()
    // Use a ternary operator to make sure that the document object is defined
    this.el =
      typeof document !== 'undefined' ? document.createElement('div') : null
  }

  componentDidMount = () => {
    if (this.el !== null) {
      this.el.tabIndex = -1
      this.el.setAttribute('role', 'group')
      this.el.setAttribute('id', 'portal-focus-wrapper')
      this.el.style.outline = 'none'
    }
    portalRoot.appendChild(this.el)
  }

  componentWillUnmount = () => {
    portalRoot.removeChild(this.el)
  }

  render() {
    const { children } = this.props

    // Check that this.el is not null before using ReactDOM.createPortal
    if (this.el) {
      return ReactDOM.createPortal(children, this.el)
    } else {
      return null
    }
  }
}
