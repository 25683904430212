import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Rect from '@reach/rect'

import styles from './buyButton.module.css'

import ExternalLink from './externalLink'
import Portal from './portal'
import LinkIcon from './linkIcon'

class BuyButton extends Component {
  constructor(props) {
    super(props)
    this.menu = React.createRef()
    this.state = {
      isActive: false,
      menuActive: false,
    }
  }

  openDropdown() {
    this.setState({ menuActive: true }, () => {
      this.menu.current.focus({
        preventScroll: true,
      })
    })
  }

  closeDropdown() {
    this.setState({ menuActive: false })
  }

  keyDown = event => {
    if (event.keyCode === 27) {
      this.closeDropdown()
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDown, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown, false)
  }

  render() {
    const { node } = this.props
    const { frontmatter } = node
    const { isActive, menuActive } = this.state

    if (!isActive || frontmatter.purchase_links.length <= 0) {
      return null
    }

    return (
      <Rect observe={menuActive}>
        {({ rect, ref }) => {
          return (
            <>
              <button
                ref={ref}
                className="button button--wide button--green"
                aria-expanded={menuActive}
                aria-haspopup="true"
                aria-controls={`buy-links-${node.id}`}
                onClick={this.openDropdown.bind(this)}
              >
                Buy Now
              </button>
              {menuActive && (
                <Portal>
                  <nav
                    id={`buy-links-${node.id}`}
                    ref={this.menu}
                    aria-labelledby={`buy-wine-heading-${node.id}`}
                    tabIndex={-1}
                  >
                    <div
                      className={styles.overlay}
                      aria-hidden="true"
                      onClick={this.closeDropdown.bind(this)}
                    />
                    {rect && (
                      <div
                        className={styles.root}
                        style={{
                          left: `${rect.x}px`,
                          top: `${rect.y}px`,
                        }}
                      >
                        <h2
                          id={`buy-wine-heading-${node.id}`}
                          className="button button--wide button--green button--placeholder"
                        >
                          Buy Now
                        </h2>
                        <ul className={styles.list}>
                          {frontmatter.purchase_links.map((link, index) => (
                            <li key={index}>
                              <ExternalLink href={link.url}>
                                <LinkIcon label={link.label} />
                                {link.label}
                              </ExternalLink>
                            </li>
                          ))}
                        </ul>
                        <button
                          className="visuallyhidden"
                          onClick={this.closeDropdown.bind(this)}
                        >
                          Close Purchase Links
                        </button>
                      </div>
                    )}
                  </nav>
                </Portal>
              )}
            </>
          )
        }}
      </Rect>
    )
  }
}

BuyButton.propTypes = {
  node: PropTypes.object.isRequired,
}

export default BuyButton
