import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MarkdownWithHtml from 'react-markdown/with-html'

import styles from './wineDetails.module.css'

import Image from './image'
import BuyButton from './buyButton'
import IconArrowPrev from '../images/icon-arrow-prev.inline.svg'

const WineDetails = ({ wine }) => {
  const { fields, html, frontmatter } = wine

  return (
    <div className={classNames(`bg-${fields.name}`, styles.root)}>
      <div className={styles.wrapper}>
        <div className={styles.content} tabIndex={0}>
          <div className={styles.info}>
            <h1
              className="title"
              dangerouslySetInnerHTML={{
                __html: frontmatter.title.replace(' ', '<br />'),
              }}
            />
            <h2 className={classNames('subtitle', styles.subtitle)}>
              {frontmatter.origin}
            </h2>
            <MarkdownWithHtml
              source={html}
              escapeHtml={false}
              className={styles.description}
            />
          </div>
          <ul className={styles.attributes}>
            {frontmatter.appellation && (
              <li>
                <h3 className="subtitle subtitle--small">Appellation</h3>
                <ol>
                  {frontmatter.appellation.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ol>
              </li>
            )}
            {frontmatter.varietals && (
              <li>
                <h3 className="subtitle subtitle--small">Varietal Blend</h3>
                <ol>
                  {frontmatter.varietals.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ol>
              </li>
            )}
          </ul>
          <BuyButton node={wine} />
          {frontmatter.palette.description && (
            <a href="#palette" className={styles.palette}>
              <span className="visuallyhidden">
                Learn more about the {frontmatter.title} palette and wine
                characteristics &darr;
              </span>
              <IconArrowPrev />
            </a>
          )}
        </div>
        {frontmatter.featured_image && (
          <Image
            node={frontmatter.featured_image.src}
            alt={frontmatter.featured_image.alt}
            className={styles.image}
          />
        )}
      </div>
    </div>
  )
}

WineDetails.propTypes = {
  wine: PropTypes.object.isRequired,
}

export default WineDetails
